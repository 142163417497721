/* .grid-list{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.6fr 0.5fr 0.6fr 2.3fr;
    grid-row-gap: 1rem;
} */

.span-label{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    letter-spacing: 0.258333px;

    color: #868686;
    vertical-align: middle !important;
}

.tabs{
    display: flex;
}

.tabs > h6 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    text-align: justify;
    letter-spacing: 0.322917px;
    color: #2B388F;
    padding-right: 80px;
}