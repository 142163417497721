@font-face {
  font-family: 'AvenirNextRegular';
  src: local('AvenirNextRegular'),
    url(./assets/fonts/AvenirNextLTPro-MediumIt.otf) format('opentype');
}

:root {
  --primary: #262f46;
  --light: #ffff;
  --green: #3ac569;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: 'AvenirNext', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.padding-standard {
  padding-top: 1rem;
}

.frame-content {
  border-top: 1.5rem solid rgb(235, 236, 244);
  border-bottom: 1rem solid rgb(235, 236, 244);
  border-right: 1rem solid rgb(235, 236, 244);
  border-left: 1rem solid rgb(235, 236, 244);
  margin-left: -2vw;
  box-shadow: 0rem 0rem 0rem 0rem rgb(235, 236, 244);
  /* border-radius: 2.3rem; */
  
  padding: 3rem;
  max-height: 100vh;
  overflow: scroll;
}
.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.page-title {
  font-family: 'AvenirNext', serif;
}
input {
  text-align: left;
  color: #797979;
}
input::placeholder {
  color: #B1B1B1 !important;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
::-webkit-scrollbar-thumb {
  background: transparent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/* input[type=number] {
    -moz-appearance:textfield; 
} */

textarea::-webkit-input-placeholder {
  color: #B1B1B1 !important;
}

textarea:-moz-placeholder { /* Firefox 18- */
  color: #B1B1B1 !important;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
  color: #B1B1B1 !important;
}

textarea:-ms-input-placeholder {
  color: #B1B1B1 !important; 
}

textarea::placeholder {
  color: #B1B1B1 !important; 
  font-size: 16px;
}

.pointer{
  cursor: pointer;
}

.logo{
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}